@import "constants";

#collection-info {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  height: 400px;
  background: $gold;
  margin-top: 100px;

  .info-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $beige;
    text-align: center;

    .info-top-text {
      font-family: $body-font;
      font-weight: 700;
      font-size: 18px;
    }

    .info-middle-text {
      font-family: $header-font;
      line-height: 80px;
      font-size: 64px;
      margin: 12px auto 16px;
    }

    .info-bottom-text {
      font-family: $body-font;
      font-weight: normal;
      font-size: 18px;
      line-height: 34px;
      max-width: 250px;
    }
  }
}

@media (max-width: $mobile-screen-width) {
  #collection-info {
    flex-direction: column;
    height: auto;
    gap: 50px;
    padding: 50px;

    .info-block {
      .info-middle-text {
        font-size: 36px;
        line-height: 60px;
        margin: -4px auto 0;
      }

      .info-bottom-text {
        line-height: 24px;
      }
    }
  }
}
