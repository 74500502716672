// responsive
$mobile-screen-width: 1140px;

// typography
$header-font: "Righteous", sans-serif;
$body-font: "Oxygen", sans-serif;
$mono-font: "Roboto Mono", monospace;

// colors
$gold: #9e7509;
$beige: #fbf6e0;
$pink: #9e0932;
$black: #000406;
$grey: #e0e0e0;

// animation
$animation-time: 0.3s;

// positioning
$body-line-left: 180px;
$body-line-right: 230px;
$mobile-line-left: 45px;

// sizing
$main-content-width: 1080px;
$body-line-size: 3px;
$mobile-content-horizontal-padding: 20px;

// misc
$faded-background: rgba(14, 14, 14, 0.5);
