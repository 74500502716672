#collection-info {
  height: 400px;
  background: #9e7509;
  justify-content: center;
  align-items: center;
  gap: 100px;
  margin-top: 100px;
  display: flex;
  position: relative;
}

#collection-info .info-block {
  color: #fbf6e0;
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

#collection-info .info-block .info-top-text {
  font-family: Oxygen, sans-serif;
  font-size: 18px;
  font-weight: 700;
}

#collection-info .info-block .info-middle-text {
  margin: 12px auto 16px;
  font-family: Righteous, sans-serif;
  font-size: 64px;
  line-height: 80px;
}

#collection-info .info-block .info-bottom-text {
  max-width: 250px;
  font-family: Oxygen, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 34px;
}

@media (max-width: 1140px) {
  #collection-info {
    height: auto;
    flex-direction: column;
    gap: 50px;
    padding: 50px;
  }

  #collection-info .info-block .info-middle-text {
    margin: -4px auto 0;
    font-size: 36px;
    line-height: 60px;
  }

  #collection-info .info-block .info-bottom-text {
    line-height: 24px;
  }
}

/*# sourceMappingURL=index.a8081bc7.css.map */
